<template>
  <Sticky>
    <div class="field-header">
      <div class="search-wrap">
        <Search
          v-model="state.search"
          placeholder="输入搜索词，多个词之间用逗号分隔"
          background="#0C86FE"
          @search="searchHandle"
          @clear="clearHandle"
        />
      </div>
      <div class="filter">
        <span>筛选</span>
        <Icon class-prefix="iconfont" name="shaixuan" size="16" color="#fff" @click='filterHandle' />
      </div>
    </div>
    <div class="bar"></div>
  </Sticky>
  <ListHead @on-change="changeHandle" :total="state.count" :show="false" :sortOpt="sortOpt" />
  <van-list
    ref="myList"
    v-model:loading="state.loading"
    :finished="state.finished"
    :finished-text="state.list.length ? '没有更多了' : ''"
    offset="20"
    @load="onLoad"
  >
    <Item  v-for="item in state.list" :key="item" :detail="item" @on-click="itemClickHandle" />
  </van-list>
  <Empty v-if="state.list.length <= 0 && state.finished" />
  <BackTop />
  <Popup
    v-model:show="show"
    round
    position="bottom"
    :style="{ height: '50%' }"
  >
    <Filter
      :list="state.filterList"
      @on-reset="resetHandle"
      @on-ok="okHandle"
      @on-change="filterChange"
    >
      <div class="date-range">
        <div class="title">收藏时间</div>
        <Cell title="请选择收藏时间" :value="text" is-link @click="calendarShow = true" />
      </div>
    </Filter>
  </Popup>
  <Calendar
    v-model:show="calendarShow"
    title="收藏时间"
    :min-date="minDate"
    :max-date="maxDate"
    color="#007aff"
    type="range"
    @confirm="onConfirm"
  />
</template>

<script>
import { onActivated, reactive, ref } from 'vue'
import { onBeforeRouteLeave, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { List, Sticky, Icon, Popup, Search, Calendar, Cell } from 'vant'
import Empty from '@/components/empty/'
import ListHead from '../list/components/ListHead'
import Item from '@/components/item/'
import BackTop from '@/components/backTop/'
import Filter from '../list/components/Filter'
import { getLibraryData, getLibraryTags } from '@/api/'
import { deleteObjEmptyData, getToday } from '@/utils/'
export default {
  name: 'library',
  components: {
    'van-list': List,
    Empty,
    Sticky,
    Icon,
    ListHead,
    Item,
    BackTop,
    Popup,
    Filter,
    Search,
    Calendar,
    Cell
  },
  setup () {
    // document.title = '我的图书馆'
    const now = getToday()
    const router = useRouter()
    const store = useStore()
    const state = reactive({
      list: [],
      loading: false,
      finished: false,
      count: 0,
      total: 0,
      page: 0,
      ordering: '',
      filterList: [],
      filter: null,
      impactFactor: '',
      apiFilter: null,
      filterItem: '',
      search: '',
      created_at__date__range: ''
    })
    const text = ref('')
    const show = ref(false)
    const calendarShow = ref(false)
    const isFieldEmpty = ref(true)
    const showDashboard = ref(false)
    const myList = ref(null)
    const sortOpt = [
      { text: '收藏时间', value: '-created_at', key: 'ordering', type: 'desc' },
      { text: '收藏时间', value: 'created_at', key: 'ordering', type: 'asc' },
      { text: '发表时间', value: '-article__publish_year', key: 'ordering', type: 'desc' },
      { text: '发表时间', value: 'article__publish_year', key: 'ordering', type: 'asc' },
      { text: '影响因子', value: '-article__impact_factor', key: 'ordering', type: 'desc' },
      { text: '影响因子', value: 'article__impact_factor', key: 'ordering', type: 'asc' }
    ]

    // 获取筛选项
    getLibraryTags().then(res => {
      state.apiFilter = res.data
      const language = {
        title: '语言类型',
        key: 'article__language',
        isMultiply: false,
        lables: res.data.language.map(item => ({
          name: item.name,
          value: item.id
        }))
      }
      const articleType = {
        title: '文献类型',
        key: 'article__article_type__id__in',
        isMultiply: true,
        lables: res.data.article_type.map(item => ({
          name: item.name,
          value: item.id
        }))
      }
      state.impactFactor = res.data.impact_factor
      if (JSON.stringify(deleteObjEmptyData(res.data)) === '{}') {
        isFieldEmpty.value = false
      }
      state.filterList.push(language, articleType)
    })

    const resetState = () => {
      state.list = []
      state.loading = false
      state.finished = false
      state.page = 0
    }
    // 获取文献列表
    const fetchLiteratures = () => {
      const params = deleteObjEmptyData({
        page: state.page,
        ordering: state.ordering,
        search: state.search,
        created_at__date__range: state.created_at__date__range,
        ...state.filter
      })
      getLibraryData(params).then(res => {
        if (res.code === 200) {
          console.log(res)
          state.list.push(...res.data.data)
          state.count = res.data.page.count
          state.total = res.data.page.count
          state.loading = false
          if (state.list.length >= state.count) {
            state.finished = true
          }
        }
      })
    }
    // 分页加载
    const onLoad = () => {
      state.page++
      fetchLiteratures()
    }
    // 筛选
    const filterHandle = () => {
      if (isFieldEmpty.value) {
        show.value = true
      }
    }
    // 排序
    const changeHandle = (item) => {
      console.log(item)
      state.ordering = item.value
      resetState()
      myList.value.check()
    }
    // 重置筛选
    const resetHandle = (current, currentName) => {
      if (current.impact_factor__range === state.impactFactor.join(',')) {
        delete current.impact_factor__range
      }
      state.created_at__date__range = ''
      text.value = ''
      state.filter = current
    }
    // 筛选确定
    const okHandle = (current, currentName) => {
      if (current.impact_factor__range === state.impactFactor.join(',')) {
        delete current.impact_factor__range
      }
      state.filter = current
      show.value = false
      resetState()
      if (!showDashboard.value) {
        myList.value.check()
      }
    }
    // 跳转详情
    const itemClickHandle = (item) => {
      router.push(`/detail?id=${item.article_id}`)
    }
    // 筛选项变化时
    const filterChange = (current) => {
      if (current.impact_factor__range === state.impactFactor.join(',')) {
        delete current.impact_factor__range
      }
      // getAticleCount({
      //   bio_markers: route.query.search,
      //   ...current
      // }).then(res => {
      //   if (res.code === 200) {
      //     state.total = res.data.total
      //   }
      // })
    }
    // 搜索
    const searchHandle = () => {
      // router.push('/search')
      resetState()
      myList.value.check()
    }
    // 清除搜索
    const clearHandle = () => {
      resetState()
      myList.value.check()
    }
    // 日期选择确认
    const formatDate = (date) => `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    const onConfirm = (values) => {
      calendarShow.value = false
      const [start, end] = values
      state.created_at__date__range = `${formatDate(start)},${formatDate(end)}`
      text.value = `${formatDate(start)} - ${formatDate(end)}`
    }

    onActivated(() => {
      // document.title = '我的图书馆'
    })

    onBeforeRouteLeave((to, from) => {
      if (to.path === '/detail') {
        store.commit('catchComponents/GET_CATCHE_COMPONENTS', ['layout', 'library'])
      } else {
        store.commit('catchComponents/GET_CATCHE_COMPONENTS', [])
      }
    })

    return {
      state,
      sortOpt,
      onLoad,
      show,
      text,
      calendarShow,
      showDashboard,
      myList,
      changeHandle,
      filterHandle,
      okHandle,
      resetHandle,
      itemClickHandle,
      filterChange,
      searchHandle,
      clearHandle,
      onConfirm,
      minDate: new Date(2020, 2, 1),
      maxDate: new Date(now.getFullYear(), now.getMonth() + 1, now.getDate())
    }
  }
}
</script>

<style lang="less" scoped>
.field-header {
  padding: 12px 16px;
  background: #0C86FE;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .search-wrap {
    width: 280px;
    &:deep(.van-search) {
      border-radius: 4px;
      padding: 0;
    }
    &:deep(input) {
      color: #fff;
      &::-webkit-input-placeholder {
        color: #fff;
      }
    }
    & .van-search:deep(.van-cell) {
      padding: 5px 8px 5px 0;
      background: transparent;
    }
    &:deep(.van-icon-search) {
      color: #fff;
    }
     &:deep(.van-search__content) {
      background: rgba(255, 255, 255, 0.1);
    }
  }
  .search {
    width: 280px;
    height: 36px;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    padding: 7px 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .search-name {
      font-size: 16px;
      font-weight: 400;
      color: #FFFFFF;
    }
  }
  .filter {
    display: flex;
    font-size: 14px;
    color: #FFFFFF;
    line-height: 14px;
    & > span {
      margin-right: 8px;
    }
  }
}
.search-type {
  padding: 14px 15px 14px 16px;
  background: #FFFFFF;
  display: flex;
  justify-content: space-between;
  .label {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 22px;
    display: flex;
    align-items: center;
  }
  .subscribe-wrap {
    margin-left: 17px;
    display: flex;
    align-items: center;
    .subscribe{
      width: 54px;
      height: 28px;
      border-radius: 4px;
      border: 1px solid #0C86FE;
      font-size: 14px;
      font-weight: 400;
      color: #0C86FE;
      line-height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.date-range {
  // padding: 0 0 0 14px;
  // margin-top: 20px;
  .title {
    font-size: 16px;
    font-weight: 500;
    color: #212325;
  }
  &:deep(.van-cell) {
    padding-left: 0;
    padding-top: 16px;
    padding-bottom: 0;
  }
}
.bar {
  height: 12px;
  background: #F5F7FA;
}
</style>
