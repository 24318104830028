<template>
  <div class="filter-wrap">
    <div class="content-wrap">
      <slot></slot>
      <div
        v-for="item in list"
        :key="item.key"
        class="item-wrap"
        :class="{'bottom-border': item.bottom}"
      >
        <div v-if="!item.type">
          <div class="title">{{ item.title }}</div>
          <div class="select-item-wrap">
            <div
              class="select-item"
              :class="[ { 'select-item-active': checkActive(item,label) } ]"
              v-for="label in item.lables"
              :key="label.value"
              @click="clickHandle(item, label)"
            >
              {{ label.name }}
            </div>
          </div>
        </div>
        <div v-if="item.type === 'slide'">
          <div class="title">
            <span>{{ item.title }}</span>
            <span class="val-wrap">{{state.current[item.key][0]}} ~ {{state.current[item.key][1]}}</span>
          </div>
          <div class="my-slide">
            <div class="number left">{{ item.lables[0] }}</div>
            <Slider
              v-model="state.current[item.key]"
              range
              :min="item.lables[0]" :max="item.lables[1]"
              @change="(value) => { onChange(value,item) }" />
            <div class="number right">{{ item.lables[1] }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-wrap">
      <div class="reset van-hairline--surround" @click="resetHandle">重置</div>
      <div class="ok" @click="okHandle">确认<span v-if="total">{{ total }}</span></div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, watch } from 'vue'
import { Slider, Toast } from 'vant'
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    currValArr: {
      type: Array,
      default: () => []
    },
    list: {
      type: Array,
      default: () => ([])
    },
    reset: {
      type: Boolean,
      default: false
    },
    total: {
      type: Number,
      default: 0
    }
  },
  components: {
    Slider
  },
  setup (props, context) {
    const { emit } = context
    const state = reactive({
      current: {},
      list: [],
      currentName: {}
    })
    const value = ref([])
    // 设置选中数据列表
    props.list.forEach(item => {
      state.current[item.key] = []
      state.currentName[item.key] = []
      if (item.type === 'slide') {
        state.current[item.key] = item.lables.map(item => item)
      }
    })
    // 设置选中样式
    const checkActive = (item, label) => {
      if (item.isMultiply === false) {
        state.current[item.key].length = 1
      }
      return state.current[item.key].indexOf(label.value) !== -1
    }
    // 选中逻辑
    const clickHandle = (item, label) => {
      if (item.isMultiply === false) {
        // 单选
        if (state.current[item.key][0] === label.value) {
          state.current[item.key][0] = null
          state.currentName[item.key][0] = ''
        } else {
          state.current[item.key][0] = label.value
          state.currentName[item.key][0] = label.name
        }
      } else {
        // 多选
        if (state.current[item.key].indexOf(label.value) === -1) {
          // 当前数组中没有该值则push到数组
          state.current[item.key].push(label.value)
          state.currentName[item.key].push(label.name)
        } else {
          // 当前数组中有该值，找到该值下标并删除
          state.current[item.key].splice(state.current[item.key].indexOf(label.value), 1)
          state.currentName[item.key].splice(state.currentName[item.key].indexOf(label.name), 1)
        }
      }
      const filter = {}
      Object.keys(state.current).forEach(element => {
        if (state.current[element].join(',') !== '') {
          filter[element] = state.current[element].join(',')
        }
      })
      emit('on-change', filter, state.currentName)
    }

    // 重置
    const resetHandle = () => {
      const filter = {}
      const filterName = {}
      props.list.forEach(item => {
        state.current[item.key] = []
        state.currentName[item.key] = []
        if (item.type === 'slide') {
          state.current[item.key] = item.lables.map(item => item)
        }
      })
      Object.keys(state.current).forEach(element => {
        if (state.current[element].join(',') !== '') {
          filter[element] = state.current[element].join(',')
        }
        if (state.currentName[element].join(',') !== '') {
          filterName[element] = state.currentName[element].join(',')
        }
      })
      emit('on-reset', filter, filterName)
    }
    watch(() => props.reset, (isReSet) => {
      resetHandle()
    })
    watch(() => props.total, (val) => {
      if (val === 0) {
        Toast('没有找到相关文献')
      }
    })
    // 确认
    const okHandle = () => {
      const filter = {}
      const filterName = {}
      Object.keys(state.current).forEach(element => {
        if (state.current[element].join(',') !== '') {
          filter[element] = state.current[element].join(',')
        }
        if (state.currentName[element].join(',') !== '') {
          filterName[element] = state.currentName[element].join(',')
        }
      })
      emit('on-ok', filter, filterName)
    }

    const onChange = () => {
      const filter = {}
      Object.keys(state.current).forEach(element => {
        if (state.current[element].join(',') !== '') {
          filter[element] = state.current[element].join(',')
        }
      })
      emit('on-change', filter)
    }

    return {
      state,
      value,
      checkActive,
      clickHandle,
      resetHandle,
      okHandle,
      onChange
    }
  }
}
</script>

<style lang="less" scoped>
.filter-wrap {
  height: 100%;
  background-color: #fff;
  box-sizing: border-box;
  .bottom {
    height: 88px;
  }
  .content-wrap {
    padding: 20px 0 20px 14px;
    height: calc(100% - 128px);
    overflow-y: auto;
    .item-wrap {
      margin-top: 30px;
      &:nth-of-type(1) {
        margin-top: 0;
      }
      .title {
        font-size: 16px;
        font-weight: 500;
        color: #212325;
        .val-wrap {
          font-size: 14px;
          color: #666666;
          margin-left: 16px;
        }
      }
      .select-item-wrap {
        display: flex;
        /*justify-content: space-between;*/
        flex-wrap: wrap;
        & /deep/ .van-hairline--surround::after {
          border-color: #007AFF;
          border-radius: 5px;
        }
        .select-item {
          margin-right: 12px;
          padding: 4px 12px;
          background: #EBF5FF;
          border-radius: 14px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          color: #212325;
          margin-top: 16px;
        }
        .select-item-active {
          background: #0C86FE;
          font-weight: 500;
          color: #fff;
        }
      }
      .my-slide {
        margin-top: 20px;
        padding-right: 40px;
        display: flex;
        align-items: center;
        &:deep(.van-slider__button) {
          width: 10px;
          height: 10px;
          border: 2px solid #007FFF;
        }
        .number {
          min-width: 32px;
          font-size: 14px;
          font-weight: 400;
          color: #627386;
          line-height: 10px;
        }
        .left {
          text-align: right;
          margin-right: 14px;
        }
        .right {
          text-align: left;
          margin-left: 14px;
        }
      }
    }
  }
  .footer-wrap {
    height: 88px;
    border-top: 1px solid #e3ebf4;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px 0 14px;
    // position: absolute;
    // bottom: 0;
    background: #fff;
    width: 100%;
    box-sizing: border-box;
    & /deep/ .van-hairline--surround::after {
      border-color: #007AFF;
      border-radius: 5px;
    }
    .reset {
      width: 167px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      color: #007aff;
    }
    .ok {
      width: 167px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      color: #fff;
      background: #007aff;
      border-radius: 4px;
    }
  }
  .bottom-border {
    position: relative;
    padding-bottom: 24px;
    &::after {
      content: '';
      position: absolute;
      box-sizing: border-box;
      content: ' ';
      pointer-events: none;
      right: 0;
      bottom: 0;
      left: 0;
      border-bottom: 1px solid #E4E4E4;
      transform: scaleY(.5);
    }
  }
}
</style>
